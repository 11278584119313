<template>
  <div class="divBox">
    <v-list
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      ref="table"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="handleAdd"></v-button>
        <!-- <v-button text="导出"></v-button> -->
      </template>
      <template #searchSlot>
        <v-select
          clearable
          :options="districtListOptions"
          v-model="searchParam.districtCode"
          label="选择区级"
          @change="districtCodeChange"
          @clear="districtCodeclear"
        />
        <v-select
          clearable
          :options="streetListOptions"
          v-model="searchParam.streetId"
          label="选择街道"
          @change="streetIdChange"
          @clear="streetIdClear"
        />
        <v-select
          clearable
          :options="structuringOptions"
          v-model="searchParam.structuringId"
          label="选择社区"
          @clear="structuringIdClear"
        />
        <community-select v-model="searchParam.communityId" label="选择园区" />
        <v-select
          clearable
          :options="orderStatusOptions"
          v-model="searchParam.status"
          label="选择订单状态"
        />

        <v-input label="订单编号" v-model="searchParam.serialNumber" />
        <v-input label="场地名称" v-model="searchParam.placeName" />
        <v-input label="用户名" v-model="searchParam.userName" />
        <v-input label="联系电话" v-model="searchParam.mobileNum" />
        <v-input label="组织名称" v-model="searchParam.orgName" />
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="scope.row.status == 11"
          text="确认"
          type="text"
          @click="sureCancelOrder(scope.row)"
        ></v-button>
        <v-button
          v-if="scope.row.status != 3"
          text="取消订单"
          type="text"
          @click="handleCancelOrder(scope.row)"
        ></v-button>
      </template>
    </v-list>
    <!-- 新增弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="新增订单"
      :visible.sync="dialogFormVisible"
      width="30%"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="选择区级">
          <v-select
            placeholder="请选择区级"
            clearable
            :options="districtListOptions"
            v-model="form.districtCode"
            @change="districtCodeChange"
            @clear="districtCodeclear(1)"
          />
        </el-form-item>
        <el-form-item label="选择街道">
          <v-select
            placeholder="请选择街道"
            clearable
            :options="streetListOptions"
            v-model="form.streetId"
            @change="streetIdChange"
            @clear="streetIdClear(1)"
          />
        </el-form-item>
        <el-form-item label="选择社区">
          <v-select
            clearable
            placeholder="请选择社区"
            :options="structuringOptions"
            v-model="form.structuringId"
            @change="structuringIdChange"
            @clear="structuringIdClear(1)"
          />
        </el-form-item>
        <el-form-item label="选择场地">
          <v-select
            placeholder="请选择场地"
            clearable
            :options="placeList"
            v-model="form.placeId"
            @change="placeChange"
          />
        </el-form-item>
        <el-form-item label="选择预约日期" v-if="form.placeId">
          <v-date
            v-model="time"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
          >
          </v-date>
        </el-form-item>
        <el-form-item label="选择时间段" v-if="time">
          <v-select
            clearable
            :options="scheduleIdList"
            v-model="form.scheduleIdList"
            :multiple="true"
          />
        </el-form-item>
        <el-form-item label="预约人手机号">
          <v-input v-model="form.mobiles" placeholder="请输入预约人手机号" />
        </el-form-item>
        <el-form-item label="人数">
          <v-input
            placeholder="请输入预约人数"
            v-model="form.bookingCnt"
            type="number"
          ></v-input>
        </el-form-item>
        <el-form-item label="备注">
          <v-input
            v-model="form.remark"
            type="textarea"
            placeholder="请输入备注信息"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button size="medium" @click="backDialog()" text="返回"></v-button>
        <v-button size="medium" @click="submit()" text="保存"></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getStreetCommunityListUrl,
  placeOrderListPagingUrl,
  sureOrderCancelUrl,
  placeListPagingUrl,
  getSessionUrl,
  getAppointUrl,
  getCommunityListByTenantIdUrl,
} from "./api.js";
import { getOption, createImgVNode } from "@/utils/utils.js";
import { placeOrderStatus } from "./map.js";
import { Message, MessageBox } from "element-ui";
import { delPlaceTypeUrl, placeOrderCancelUrl } from "./api";

export default {
  name: "placeOrderList",
  data() {
    return {
      searchParam: {
        communityId: null,
        status: null,
        serialNumber: null,
        placeName: null,
        userName: null,
        mobileNum: null,
        orgName: null,
        districtCode: null, //区域code
        streetId: null, //街道id
        structuringId: null, //社区id
      },
      placeList: [], //预约场地列表
      scheduleIdList: [], //预约时间段列表
      allList: [],
      communitListOptions: [],
      orderStatusOptions: [],
      districtListOptions: [], //区域下拉列表
      streetListOptions: [], //街道下拉列表
      structuringOptions: [], //社区下拉列表

      dialogFormVisible: false,
      choosedDataRow: {},
      temp: {
        scheduleList: [],
      },

      tableUrl: placeOrderListPagingUrl,
      headers: [
        // {
        //   prop: "id",
        //   label: "订单Id",
        // },
        {
          prop: "serialNumber",
          label: "订单编号",
        },
        {
          prop: "districtName",
          label: "场地归属",
          formatter: (row, prop) => {
            let str =
              row.districtName +
              "-" +
              row.streetName +
              "-" +
              row.structuringName;
            return str;
          },
        },
        {
          prop: "placeName",
          label: "场地名称",
        },
        {
          prop: "address",
          label: "场地地址",
        },
        // {
        //   prop: "picList",
        //   label: "场地图片",
        //   formatter: (row, prop) => createImgVNode(this, row, prop),
        // },
        {
          prop: "placeTypeName",
          label: "场地类型",
        },
        // {
        //   prop: "count",
        //   label: "容纳人数",
        // },
        {
          prop: "equip",
          label: "场地设备",
        },

        {
          prop: "userName",
          label: "用户名",
        },
        {
          prop: "mobileNum",
          label: "联系电话",
        },
        {
          prop: "orgName",
          label: "组织名称",
        },

        {
          prop: "scheduleTimes",
          label: "预约时间",
        },
        {
          prop: "referTime",
          label: "下单时间",
        },
        {
          prop: "status",
          label: "状态",
          formatter: (row, prop) => {
            for (let i = 0; i < placeOrderStatus.length; i++) {
              if (row[prop] === placeOrderStatus[i].code) {
                return placeOrderStatus[i].label;
              }
            }
          },
        },
        {
          prop: "bookingCnt",
          label: "订单人数",
        },
        {
          prop: "remark",
          label: "订单备注",
        },
      ],
      time: null,
      form: {
        districtCode: null, //区域code
        streetId: null, //街道id
        structuringId: null, //社区id
        placeId: null, //场地id
        placeName: null,
        scheduleIdList: [],
        remark: "",
        bookingCnt: 1,
        mobiles: "",
      },
    };
  },
  watch: {
    time: {
      handler(nVal, oVal) {
        if (nVal) {
          let year = nVal.getFullYear();
          let month =
            nVal.getMonth() + 1 < 10
              ? "0" + (nVal.getMonth() + 1)
              : nVal.getMonth() + 1;
          let day = nVal.getDate() < 10 ? "0" + nVal.getDate() : nVal.getDate();
          let str = `${year}-${month}-${day}`;
          console.log(str);
          this.getScheduleIdList(str);
        }
      },
    },
  },
  async mounted() {
    this.getStatusOptions();
    await this.getPlaceList();
    await this.getStreetCommunityList();
    // this.addGetData();
  },
  methods: {
    placeChange(value) {
      this.placeList.forEach((item) => {
        if (item.id == this.form.placeId) {
          this.form.placeName = item.address;
        }
      });
    },
    submit() {
      if (!this.form.placeId || this.form.placeId == "") {
        this.$message.error("请选择预约场地！");
        return;
      }
      if (this.form.scheduleIdList.length == 0) {
        this.$message.error("请选择预约时间段！");
        return;
      }
      if (!this.form.mobiles || this.form.mobiles == "") {
        this.$message.error("请填写预约人手机号！");
        return;
      }
      if (this.form.mobiles.length != 11) {
        this.$message.error("请填写正确格式预约人手机号！");
        return;
      }
      if (!this.form.remark || this.form.remark == "") {
        this.$message.error("请填写备注信息！");
        return;
      }
      let params = {
        placeId: this.form.placeId,
        placeName: this.form.placeName,
        scheduleIdList: this.form.scheduleIdList,
        remark: this.form.remark,
        mobiles: this.form.mobiles,
        bookingCnt: this.form.bookingCnt,
        source: "2",
      };
      this.$axios.post(`${getAppointUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.dialogFormVisible = false;
          this.$message.success("操作成功！");
          this.$refs.table.search();
        }
      });
    },
    backDialog() {
      this.dialogFormVisible = false;
    },
    addGetData() {
      let params = {
        tenantId: this.$store.state.app.userInfo.tenantId,
      };
      this.$axios
        .get(`${getCommunityListByTenantIdUrl}`, { params: params })
        .then((res) => {
          if (res.code === 200) {
            this.form.districtCode = res.data.districtCode; //区域code
            this.form.streetId = res.data.streetId; //街道id
            this.form.structuringId = res.data.structuringId; //社区id
          }
        });
    },
    // 新增订单
    async handleAdd() {
      this.form.districtCode = null;
      this.form.streetId = null;
      this.form.structuringId = null;
      this.dialogFormVisible = true;
      await this.getStreetCommunityList();
      this.addGetData();
      this.form.placeId = null;
      this.form.placeName = null;
      this.form.scheduleIdList = [];
      this.form.remark = "";
      this.form.bookingCnt = 1;
      this.form.mobiles = "";
      this.time = null;
    },
    // 获取时间段列表
    getScheduleIdList(date) {
      let params = {
        placeDate: date,
        placeId: this.form.placeId,
      };
      this.$axios.get(`${getSessionUrl}`, { params: params }).then((res) => {
        res.data.forEach((item) => {
          item.label = item.beginTime + "~" + item.endTime;
          item.value = item.id;
          if (item.canOrder == 0) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        });
        this.scheduleIdList = res.data;
      });
    },
    // 获取场地列表
    async getPlaceList() {
      let params = {
        curPage: 1,
        pageSize: 1000000000,
        bookingType: 1,
        districtCode: this.form.districtCode, //区域code
        streetId: this.form.streetId, //街道id
        structuringId: this.form.structuringId, //社区id
      };
      let res = await this.$axios.get(`${placeListPagingUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        res.data.records.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
        });
        this.placeList = res.data.records;
      }
    },
    districtCodeclear() {
      //清空区级内容
      this.searchParam.districtCode = null;
      this.searchParam.streetId = null;
      this.searchParam.structuringId = null;
    },
    streetIdClear() {
      //清空街道内容
      this.searchParam.streetId = null;
      this.searchParam.structuringId = null;
    },
    structuringIdClear() {
      //清空社区内容
      this.searchParam.structuringId = null;
    },
    async structuringIdChange(value) {
      // 选择社区change
      await this.getPlaceList();
    },
    async streetIdChange(value) {
      //选择街道change
      this.searchParam.structuringId = null;
      // this.form.structuringId = null;
      this.structuringOptions = [];
      this.streetListOptions.forEach((item, index) => {
        if (item.streetId == value) {
          this.structuringOptions = item.list;
        }
      });
      await this.getPlaceList();
    },
    async districtCodeChange(value) {
      //选择区级change
      this.searchParam.streetId = null;
      this.searchParam.structuringId = null;
      // this.form.streetId = null;
      // this.form.structuringId = null;
      this.streetListOptions = [];
      this.districtListOptions.forEach((item, index) => {
        if (item.districtCode == value) {
          this.streetListOptions = item.list;
          console.log(this.streetListOptions);
        }
      });
      await this.getPlaceList();
    },
    async getStreetCommunityList() {
      //获取区县/街道/社区列表
      this.districtListOptions = [];
      let res = await this.$axios.get(`${getStreetCommunityListUrl}`);
      if (res.code === 200) {
        this.allList = res.data;
        res.data.forEach((item) => {
          item.label = item.districtName;
          item.value = item.districtCode;
          item.list.forEach((item1) => {
            item1.label = item1.streetName;
            item1.value = item1.streetId;
            item1.list.forEach((item2) => {
              item2.label = item2.structuringName;
              item2.value = item2.structuringId;
            });
          });
          this.districtListOptions.push(item);
        });
        this.districtListOptions.forEach((item) => {
          if (item.value == this.form.districtCode) {
            this.streetListOptions = item.list;
            this.streetListOptions.forEach((item1) => {
              if (item1.value == this.form.streetId) {
                this.structuringOptions = item1.list;
              }
            });
          }
        });
      }
    },
    sureCancelOrder(data) {
      this.$confirm("确认后会发送短信给预约者，是否确认该预约？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            orderId: data.id,
          };
          this.$axios
            .post(`${sureOrderCancelUrl}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.table.search();
              }
            });
        })
        .catch(() => {});
    },
    handleShowDetail(data) {
      this.dialogFormVisible = true;
      this.choosedDataRow = data;
    },

    handleCancelOrder(data) {
      this.$confirm("是否取消该预约", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            orderId: data.id,
          };
          this.$axios
            .post(`${placeOrderCancelUrl}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.table.search();
              }
            });
        })
        .catch(() => {});
    },

    getStatusOptions() {
      placeOrderStatus.forEach((item) => {
        // 过滤掉删除
        if (item.code != "1" && item.code != "4" && item.code != "5") {
          item.value = item.code;
          this.orderStatusOptions.push(item);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
